import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { JackpotService } from '@services/jackpot.service';
import { take, map } from 'rxjs';

export const winTransactionGuard: CanActivateFn = () => {
  const service = inject(JackpotService);
  const router = inject(Router);
  return service.jackpotWinTransaction$.pipe(
    take(1),
    map((transaction) => {
      if (transaction) return true;
      router.createUrlTree(['/error']);
      return false;
    }),
  );
};
