import { Routes, RouterModule } from '@angular/router';
import { jackpotGroupResolver } from '@app/resolvers/jackpot-group.resolver';
import { winTransactionResolver } from '@app/resolvers/win-transaction.resolver';
import { loggedInAuthGuard } from '@guards/logged-in-auth.guard';
import { NgModule } from '@angular/core';
import { winTransactionGuard } from '@guards/win-transaction.guard';

export const routes: Routes = [
  {
    path: 'home',
    loadChildren: () =>
      import('./features/home/home.module').then((m) => m.HomeModule),
    canActivate: [loggedInAuthGuard],
  },
  {
    path: 'win-animation-widget',
    loadComponent: () =>
      import(
        './features/win-animation-widget/win-animation-widget.component'
      ).then((m) => m.WinAnimationWidgetComponent),
    canActivate: [loggedInAuthGuard],
    resolve: {
      transaction: winTransactionResolver,
    },
  },
  {
    path: 'won-jackpot',
    loadComponent: () =>
      import('./features/won-jackpot/won-jackpot.component').then(
        (m) => m.WonJackpotComponent,
      ),
    canActivate: [loggedInAuthGuard, winTransactionGuard],
  },
  {
    path: 'footer-widget',
    loadComponent: () =>
      import('./features/footer-widget/footer-widget.component').then(
        (m) => m.FooterWidgetComponent,
      ),
    canActivate: [loggedInAuthGuard],
    resolve: {
      jackpotGroup: jackpotGroupResolver,
    },
  },
  {
    path: 'error',
    loadChildren: () =>
      import('./features/error/error.module').then((m) => m.ErrorModule),
  },
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'home',
  },
  {
    path: '**',
    redirectTo: 'home',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
