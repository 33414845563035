import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { take, filter } from 'rxjs';

import { SessionService } from '@services/session.service';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit {
  user$ = this.sessionService.user$;

  constructor(
    private breakpointObserver: BreakpointObserver,
    private sessionService: SessionService,
  ) {}

  ngOnInit(): void {
    this.initGame();
    this.initIsMobile();
  }

  private initGame(): void {
    this.user$
      .pipe(
        filter((user) => !!user),
        take(1),
      )
      .subscribe(() => {
        // fetch game here
        this.sessionService.updateAuthToken();
      });
  }

  private initIsMobile(): void {
    this.breakpointObserver
      .observe([Breakpoints.Handset])
      .pipe(untilDestroyed(this))
      .subscribe((result) => (this.sessionService.isMobile = result.matches));
  }
}
