import { HttpClient } from '@angular/common/http';
import { Injectable, inject, DestroyRef } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Router } from '@angular/router';
import { environment } from '@environment';
import { UserActivityService } from '@services/user-activity.service';
import { BehaviorSubject, interval, filter, switchMap, Observable } from 'rxjs';
import { Theme } from '@shared/types/theme.type';
import { UserInfo } from '@shared/types/user.type';

@Injectable({
  providedIn: 'root',
})
export class SessionService {
  private _user = new BehaviorSubject<UserInfo | undefined>(undefined);
  isMobile = false;
  thirdPartyToken = '';
  gameUuid?: string;
  instance?: string;
  theme?: Theme;
  readonly user$ = this._user.asObservable();

  private destroyRef = inject(DestroyRef);

  constructor(
    private http: HttpClient,
    private userActivityService: UserActivityService,
    private router: Router,
  ) {}

  setUser(userInfo: UserInfo): void {
    this._user.next(userInfo);
  }

  updateAuthToken(): void {
    // Run every 10 minutes
    interval(10 * 60 * 1000)
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        // this interval must not refresh the token trigger if the user is not active
        filter(() => !this.userActivityService.isUserInactive()),
        switchMap(() => this.fetchToken()),
      )
      .subscribe({
        next: (user) => {
          this.setUser(user);
        },
        error: () => {
          this.router.navigate(['/error']);
        },
      });
  }

  fetchToken(): Observable<UserInfo> {
    return this.http.post<UserInfo>(
      `${environment.backendUrl}/api/public/auth/token`,
      {
        token: this.thirdPartyToken,
        instance: this.instance,
      },
    );
  }
}
