import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';
import { JackpotService } from '@services/jackpot.service';
import { JackpotGroupResponse } from '@shared/types/jackpot-group';
import { Observable } from 'rxjs';

export const jackpotGroupResolver: ResolveFn<
  Observable<JackpotGroupResponse>
> = () => {
  const jackpotService = inject(JackpotService);
  return jackpotService.fetchJackpotGroup();
};
