import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';
import { JackpotService } from '@services/jackpot.service';
import { JackpotWinTransaction } from '@shared/types/jackpot-transaction';
import { Observable } from 'rxjs';

export const winTransactionResolver: ResolveFn<
  Observable<JackpotWinTransaction>
> = () => {
  const jackpotService = inject(JackpotService);
  return jackpotService.fetchJackpotWinTransaction();
};
