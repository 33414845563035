import { DOCUMENT } from '@angular/common';
import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Inject,
} from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { take, filter } from 'rxjs';

import { SessionService } from '@services/session.service';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit {
  user$ = this.sessionService.user$;
  isMobile$ = this.sessionService.isMobile$;

  constructor(
    private breakpointObserver: BreakpointObserver,
    private sessionService: SessionService,
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
    @Inject(DOCUMENT) private document: Document,
  ) {}

  ngOnInit(): void {
    this.updateBodyClass();
    this.initGame();
    this.initIsMobile();
    this.iconRegistry.addSvgIcon(
      'custom-close',
      this.sanitizer.bypassSecurityTrustResourceUrl(
        '../assets/icons/close.svg',
      ),
    );
  }

  private initGame(): void {
    this.user$
      .pipe(
        filter((user) => !!user),
        take(1),
      )
      .subscribe(() => {
        // fetch game here
        this.sessionService.updateAuthToken();
      });
  }

  private initIsMobile(): void {
    this.breakpointObserver
      .observe([Breakpoints.Handset])
      .pipe(untilDestroyed(this))
      .subscribe((result) =>
        this.sessionService.updateIsMobile(result.matches),
      );
  }

  private updateBodyClass(): void {
    const bodyClass = 'mobile-version';
    this.isMobile$.pipe(untilDestroyed(this)).subscribe((isMobile) => {
      if (isMobile) {
        this.document.body.classList.add(bodyClass);
      } else {
        this.document.body.classList.remove(bodyClass);
      }
    });
  }
}
